@import "../../../node_modules//animate.css/animate.min.css";

@import "./custom";
@import "./utils/key-frames";
@import "./utils/media-queries";
@import "./utils/mixins";
@import "./typography";

@import "./components/navbar";
@import "./components/footer";
@import "./components/page-header";
@import "./components/back-to-top";
@import "./components/cookies-approval";
@import "./components/jumbotron";
@import "./components/slider";
@import "./components/features.scss";
@import "./components/callout";
@import "./components/contact";
@import "./components/who-we-are";
@import "./components/leadership";
@import "./components/faq";
@import "./components/privacy";
@import "./components/terms";
@import './components/not-found';