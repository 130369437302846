#who-we-are-section {
  padding: 4rem 2rem 4rem 2rem;

  @include media-breakpoint-down(md) {
    padding: 0rem 0.5rem 3rem 0.5rem;
  }

  div.col-md-6 {
    height: auto;
  }

  h3 {
    text-align: center;
    color: $dark;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
    text-shadow: 0.2rem 0.3rem 0.3rem rgba($success, 0.5);

    @include media-breakpoint-down(lg) {
      font-size: 2rem;
    }

    @include media-breakpoint-down(md) {
      font-size: 1.75rem;
    }
  }

  .advantages {
    margin-top: 5rem;

    h3 {
      margin-bottom: 2rem;
      color: $success;
    }
  }

  .overview {
    h3 {
      font-size: 3rem;
      font-weight: bold;
      padding: 0em 0em 0em 0em;
      text-align: center;
      color: $dark;
      text-shadow: 0.2rem 0.3rem 0.3rem rgba($success, 0.5);

      .logo-font {
        font-size: 3.5rem;
        font-weight: 800;
        color: $secondary;
        letter-spacing: -0.5rem;
        text-shadow: 0.2rem 0.3rem 0.3rem rgba($success, 0.8);
      }

      @include media-breakpoint-down(lg) {
        font-size: 3rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 1.75rem;
        margin-bottom: 2rem;
      }
    }

    h5 {
      font-size: 1.5rem;
      text-align: center;
      font-weight: 700;
      margin-bottom: 3rem;
    }
  }

  #who-we-are-global {
    margin-bottom: 3rem;
  }

  .content {
    padding: 3rem 2rem;
    border-radius: $borderRadius;
    height: auto;
    font-size: 1.2rem;
    line-height: 1.7rem;
    background: transparent;

    &.intro {
      width: 80%;
      margin: 0 auto;
      text-align: center;

      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: left;
      }
    }

    img {
      float: left;
      width: 20%;
      height: auto;
      margin-right: 1rem;
    }

    .advantage-header-img {
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      .site-logo {
        width: 20%;
      }
    }

    .advantage {
      height: auto;
      margin-bottom: 3rem;

      h4 {
        text-align: center;
        font-weight: 600;
        text-shadow: 0.2rem 0.3rem 0.3rem rgba($success, 0.5);
      }

      img {
        width: 50%;
        max-width: 300px;
        display: block;
        margin: 0rem auto 1rem auto;
        float: none;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 2rem 1rem;
    }
  }

  .global-company-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    picture {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 80%;

      margin-top: 1rem;
      margin-bottom: 2.5rem;

      &:hover {
        animation: bounce;
        animation-duration: 2s;
      }

      @include media-breakpoint-down(xl) {
        width: 30%;
        min-width: 250px;
        margin: 0;
      }
    }

    &.right {
      @include media-breakpoint-down(xl) {
        display: none;
      }
    }
  }

  ul {
    li {
      margin-bottom: 1.5rem;
      padding-left: 0.5rem;

      i {
        color: $secondary;
        font-size: 1.5rem;
        margin-right: 1rem;
      }

      .list-heading {
        color: $primary;
        font-weight: 700;
      }
    }
  }

  .our-work {
    h3 {
      margin-bottom: 3rem;
    }

    @include media-breakpoint-down(lg) {
      .slider {
        margin-top: 2rem;
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
