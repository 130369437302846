.slider {
  padding: 0 12%;

  .carousel-control-prev-icon {
    background: transparent
      url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-rewind-fill" viewBox="0 0 16 16"><path d="M8.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L8.404 7.304Z"/><path d="M.404 7.304a.802.802 0 0 0 0 1.392l6.363 3.692c.52.302 1.233-.043 1.233-.696V4.308c0-.653-.713-.998-1.233-.696L.404 7.304Z"/></svg>')
      center/1em auto no-repeat !important;
  }
  .carousel-control-next-icon {
    background: transparent
      url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-fast-forward-fill" viewBox="0 0 16 16"><path d="M7.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C.713 12.69 0 12.345 0 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z"/><path d="M15.596 7.304a.802.802 0 0 1 0 1.392l-6.363 3.692C8.713 12.69 8 12.345 8 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692Z"/></svg>')
      center/1em auto no-repeat !important;
  }

  h2 {
    margin-bottom: 2rem;
  }

  h4 {
    margin-top: 3rem;
  }

  .carousel-item {
    text-decoration: none;
  }

  .carousel-caption {
    background-color: rgba($dark, 0.7);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    visibility: hidden;

    .caption-content {
      height: 100%;
      margin-top: 20%;

      h5 {
        font-weight: 700;
        margin-bottom: 3rem;
        text-decoration: none;
        color: $white;
      }

      p {
        font-size: 0.8rem;
      }

      .btn {
      }
    }
  }
}

@keyframes fadeIntoView {
  0% {
    visibility: visible;
    opacity: 0.2;
  }

  25% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.45;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}
