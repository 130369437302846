.jumbotron {
  background-color: $primary;
  min-height: 60vh;
  padding-top: 3rem;
  padding-bottom: 4rem;
  color: $white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .logo-font {
    font-size: 1.5rem;
    color: $secondary;
  }
}

.jumbotron-logo {
  height: 10vh;
  pointer-events: none;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.jumbo-promo {
  font-size: 1.2rem;
  font-weight: 500;
}

.jumbotron-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.jumbotron-link {
  color: #61dafb;
}

@keyframes jumbotron-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
