.callout {
  padding-top: 3rem;
  padding-bottom: 4rem;

  &--image {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    ul {
      padding-top: 2rem;

      li {
        margin-bottom: 2rem;
        font-size: 1.2rem;
        padding-left: 3rem;

        i {
          font-size: 2.5rem;
          color: $primary;
        }
      }
    }

    .btn {
      margin-top: 1.5rem;
    }
  }
}
